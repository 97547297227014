import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { SnackbarProvider } from "notistack";
import React from "react";
import { DrawerProvider } from "../contexts/DrawerContext";
import { LoginProvider } from "../contexts/LoginContext";
import { UserProvider } from "../contexts/UserContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: 1000 * 60 * 5 },
  },
});

const persister = createSyncStoragePersister({ storage: window.localStorage });

function Providers({ children }: { children: React.ReactNode }) {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
      <DrawerProvider>
        <UserProvider>
          <LoginProvider>
            <SnackbarProvider>{children}</SnackbarProvider>
          </LoginProvider>
        </UserProvider>
      </DrawerProvider>
    </PersistQueryClientProvider>
  );
}

export default Providers;
