import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid2,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { request } from "../../api/base";
import { Run } from "../../models/leaderboards";
import { toSeconds } from "../../utils/formatTime";
import isAdmin from "../../utils/isAdmin";

enum FormTypeEnum {
  SOLO = "solo",
  COOP = "coop",
  SOLO_BEHALF = "solo_behalf",
  COOP_BEHALF = "coop_behalf",
}

const timeMatch =
  /^((?<minutes>[0-5]?\d):)?(?<seconds>[0-5]?\d)(\.(?<milliseconds>\d{1}))?$/;

const formTypeSchema = z.object({
  _step: z.nativeEnum(FormTypeEnum),
});

const defaultSchema = z.object({
  score: z.coerce.number().min(1111).max(99999),
  time: z.string().regex(timeMatch, "Time must be in the format MM:SS.S"),
});

const soloSchema = defaultSchema.extend({
  _step: z.literal(FormTypeEnum.SOLO),
  coop: z.boolean(),
  video: z.string().url(),
});

const coopSchema = soloSchema.extend({
  _step: z.literal(FormTypeEnum.COOP),
  coop: z.boolean(),
  partner: z.string(),
  partner_video: z.string().url(),
});

const soloBehalfSchema = defaultSchema.extend({
  _step: z.literal(FormTypeEnum.SOLO_BEHALF),
  coop: z.boolean(),
  behalf: z.boolean().default(true),
  runner_1: z.string(),
  runner_1_video: z.string().url(),
});

const coopBehalfSchema = soloBehalfSchema.extend({
  _step: z.literal(FormTypeEnum.COOP_BEHALF),
  coop: z.boolean(),
  runner_2: z.string(),
  runner_2_video: z.string().url(),
});

const schemaConditions = z.discriminatedUnion("_step", [
  soloSchema,
  coopSchema,
  soloBehalfSchema,
  coopBehalfSchema,
]);

const formSchema = z.intersection(formTypeSchema, schemaConditions);

type FormValues = z.infer<typeof formSchema>;

function RunSubmission({
  open,
  setOpen,
  leaderboardId,
  startCoop = false,
  disableSolo = false,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  leaderboardId: string;
  startCoop?: boolean;
  disableSolo?: boolean;
}) {
  const userIsAdmin = isAdmin();
  const [error, setError] = React.useState<string | null>(null);
  const [coop, setCoop] = React.useState(startCoop);
  const [behalf, setBehalf] = React.useState(false);
  const [step, setStep] = React.useState<FormTypeEnum>(
    startCoop ? FormTypeEnum.COOP : FormTypeEnum.SOLO
  );
  const client = useQueryClient();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      _step: step,
      coop: startCoop,
    },
  });

  React.useEffect(() => {
    if (coop && behalf) setStep(FormTypeEnum.COOP_BEHALF);
    else if (coop) setStep(FormTypeEnum.COOP);
    else if (behalf) setStep(FormTypeEnum.SOLO_BEHALF);
    else setStep(FormTypeEnum.SOLO);
  }, [coop, behalf]);

  React.useEffect(() => {
    setValue("_step", step);
  }, [step]);

  const { mutate } = useMutation({
    mutationFn: request<Run>,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["leaderboard", leaderboardId] });
      setOpen(false);
      window.location.reload();
    },
    onError: (error) => {
      setError(error.detail || "An unexpected error occurred");
    },
  });

  const onSubmit = (data: FormValues) => {
    const match = data.time.match(timeMatch);
    if (!match) {
      setError("Invalid time format");
      return;
    }

    const totalSeconds = toSeconds(
      parseInt(match.groups?.minutes || "0"),
      parseInt(match.groups?.seconds || "0"),
      parseInt(match.groups?.milliseconds || "0") * 100
    );

    const body: any = {
      leaderboardId,
      score: data.score,
      time: totalSeconds,
    };

    if (data._step === FormTypeEnum.SOLO) {
      body["primary_video"] = data.video;
    } else if (data._step === FormTypeEnum.COOP) {
      body["primary_video"] = data.video;
      body["secondary_video"] = data.partner_video;
      body["secondary_runner"] = data.partner;
    } else if (data._step === FormTypeEnum.SOLO_BEHALF) {
      body["primary_video"] = data.runner_1_video;
      body["primary_runner"] = data.runner_1;
    } else if (data._step === FormTypeEnum.COOP_BEHALF) {
      body["primary_video"] = data.runner_1_video;
      body["primary_runner"] = data.runner_1;
      body["secondary_video"] = data.runner_2_video;
      body["secondary_runner"] = data.runner_2;
    }

    mutate({
      url: `/leaderboards/${leaderboardId}`,
      method: "post",
      body: body,
    });
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Submit Run</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={coop}
                  onChange={(e) => setCoop(e.target.checked)}
                  disabled={disableSolo}
                />
              }
              label="Coop"
            />
            {userIsAdmin && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={behalf}
                    onChange={(e) => setBehalf(e.target.checked)}
                  />
                }
                label="On Behalf of another runner"
              />
            )}
            <Grid2 container spacing={0}>
              <Grid2 size={4}>
                <TextField
                  label="Score"
                  autoComplete="off"
                  required
                  error={!!errors.score}
                  helperText={errors.score?.message}
                  variant="standard"
                  {...register("score")}
                />
              </Grid2>
              <Grid2 size={8}>
                <TextField
                  label="Time"
                  autoComplete="off"
                  required
                  error={!!errors.time}
                  helperText={errors.time?.message}
                  variant="standard"
                  {...register("time")}
                />
              </Grid2>
            </Grid2>
            {!behalf && (
              <TextField
                label="Video"
                autoComplete="off"
                required
                fullWidth
                error={!!errors.video}
                helperText={errors.video?.message}
                variant="standard"
                {...register("video")}
              />
            )}
            {!behalf && coop && (
              <Grid2 container spacing={0}>
                <Grid2 size={4}>
                  <TextField
                    label="Partner"
                    autoComplete="off"
                    required
                    fullWidth
                    error={!!errors.partner}
                    helperText={errors.partner?.message}
                    variant="standard"
                    {...register("partner")}
                  />
                </Grid2>
                <Grid2 size={8}>
                  <TextField
                    label="Partner Video"
                    autoComplete="off"
                    required
                    fullWidth
                    error={!!errors.partner_video}
                    helperText={errors.partner_video?.message}
                    variant="standard"
                    {...register("partner_video")}
                  />
                </Grid2>
              </Grid2>
            )}
            {behalf && (
              <Grid2 container spacing={0}>
                <Grid2 size={4}>
                  <TextField
                    label="Runner"
                    autoComplete="off"
                    required
                    fullWidth
                    error={!!errors.runner_1}
                    helperText={errors.runner_1?.message}
                    variant="standard"
                    {...register("runner_1")}
                  />
                </Grid2>
                <Grid2 size={8}>
                  <TextField
                    label="Runner Video"
                    autoComplete="off"
                    required
                    fullWidth
                    error={!!errors.runner_1_video}
                    helperText={errors.runner_1_video?.message}
                    variant="standard"
                    {...register("runner_1_video")}
                  />
                </Grid2>
              </Grid2>
            )}
            {behalf && coop && (
              <Grid2 container spacing={0}>
                <Grid2 size={4}>
                  <TextField
                    label="Runner 2"
                    autoComplete="off"
                    required
                    fullWidth
                    error={!!errors.runner_2}
                    helperText={errors.runner_2?.message}
                    variant="standard"
                    {...register("runner_2")}
                  />
                </Grid2>
                <Grid2 size={8}>
                  <TextField
                    label="Runner 2 Video"
                    autoComplete="off"
                    required
                    fullWidth
                    error={!!errors.runner_2_video}
                    helperText={errors.runner_2_video?.message}
                    variant="standard"
                    {...register("runner_2_video")}
                  />
                </Grid2>
              </Grid2>
            )}
          </DialogContent>
          {error && (
            <DialogContentText color="error" align="center">
              {error}
            </DialogContentText>
          )}
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button type="submit" disabled={Object.keys(errors).length !== 0}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

export default RunSubmission;
