import { CircularProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetLeaderboard } from "../api/requests";
import CoopLeaderboard from "./CoopLeaderboard";
import SoloLeaderboard from "./SoloLeaderboard";

export default function Leaderboard() {
  const [solo, setSolo] = React.useState(
    (localStorage.getItem("solo") || "true") === "true"
  );
  const { leaderboardId } = useParams<{ leaderboardId: string }>();

  const { data: level, isLoading } = useGetLeaderboard(leaderboardId!);

  const setSoloAndSave = (solo: boolean) => {
    setSolo(solo);
    localStorage.setItem("solo", solo.toString());
  };

  if (isLoading || !level) {
    return <CircularProgress />;
  }

  const soloLeaderboard = (
    <SoloLeaderboard level={level} toCoop={() => setSoloAndSave(false)} />
  );
  const coopLeaderboard = (
    <CoopLeaderboard level={level} toSolo={() => setSoloAndSave(true)} />
  );

  // Force coop leaderboard if the level can't be soloed
  if (!level.can_solo) {
    return coopLeaderboard;
  } else if (solo) {
    return soloLeaderboard;
  } else {
    return coopLeaderboard;
  }
}
