import React from "react";
import { Outlet } from "react-router-dom";
import Login from "./components/dialogs/Login";
import LevelsSelector from "./components/LevelsSelector";
import Navbar from "./components/Navbar";
import Providers from "./components/Providers";

var at = require("array.prototype.at");
at.shim();

function App() {
  return (
    <Providers>
      <div className="App">
        <Navbar />
        <LevelsSelector />
        <Login />
        <div id="detail">
          <Outlet />
        </div>
      </div>
    </Providers>
  );
}

export default App;
