import React, { createContext } from "react";
import { useGetUser } from "../api/requests";
import { Runner } from "../models/leaderboards";

const UserContext = createContext<{
  user: Runner | null;
  setUser: (value: Runner | null) => void;
}>({
  user: null,
  setUser: (value: Runner | null) => {},
});

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<Runner | null>(null);
  const { data } = useGetUser();

  React.useEffect(() => {
    if (data) {
      setUser(data);
    }
  }, [data]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider };
