import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const client = axios.create({
  baseURL: API_URL,
});

interface Request {
  url: string;
  method?: string;
  headers?: object;
  body?: object;
  params?: object;
}

export async function request<T>(
  { url, method, headers, body, params }: Request = {
    url: "",
    method: "get",
    headers: {},
    body: {},
    params: {},
  }
): Promise<T> {
  const token = localStorage.getItem("token");

  if (token !== null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  const onSuccess = (response: any) => response.data;

  const onError = (error: any) => {
    if (error.response) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error.message);
    }
  };

  return client
    .request({
      url,
      method,
      headers,
      params,
      data: body,
    })
    .then(onSuccess)
    .catch(onError);
}
