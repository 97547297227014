import {
  Button,
  Card,
  CircularProgress,
  Grid2 as Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetRun } from "../api/requests";
import { formatTime } from "../utils/formatTime";
import isAdmin from "../utils/isAdmin";
import EditDialog from "./dialogs/EditRun";
import YoutubeEmbed from "./YoutubeEmbed";

function Run() {
  const { runId } = useParams<{ runId: string }>();
  const [editOpen, setEditOpen] = useState<boolean>(false);

  const { data: run, isLoading } = useGetRun(runId!);
  const userIsAdmin = isAdmin();

  const runnerString =
    run?.secondary_runner !== null ? (
      <Typography>
        Runners: {run?.primary_runner.name} and {run?.secondary_runner.name}
      </Typography>
    ) : (
      <Typography>Runner: {run?.primary_runner.name}</Typography>
    );

  const runDisplay = run ? (
    <>
      <EditDialog
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        run={run.id.toString()}
      />
      <Grid
        container
        spacing={2}
        // direction={"column"}
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        // sx={{ minHeight: '100vh' }}
        marginTop={10}
      >
        <Grid size={10}>
          <Typography variant="h3">{run.leaderboard.name}</Typography>
        </Grid>
        {userIsAdmin && (
          <Grid size={2}>
            <Button variant="contained" onClick={() => setEditOpen(true)}>
              Edit Run
            </Button>
          </Grid>
        )}
        {run.secondary_video ? (
          <>
            <Grid size={6}>
              <YoutubeEmbed
                video={run.primary_video!}
                title="Primary Runner POV"
              />
            </Grid>
            <Grid size={6}>
              <YoutubeEmbed
                video={run.secondary_video}
                title="Secondary Runner POV"
              />
            </Grid>
          </>
        ) : (
          <Grid size={10}>
            <YoutubeEmbed video={run.primary_video!} title="Run Video" />
          </Grid>
        )}
        <Grid size={6}>
          <Card>
            {runnerString}
            <Typography>Rank: {run.place}</Typography>
            <Typography>Time: {formatTime(run.time)}</Typography>
            <Typography>Score: {run.score}</Typography>
          </Card>
        </Grid>
      </Grid>
    </>
  ) : null;

  return run || isLoading ? runDisplay : <CircularProgress />;
}

export default Run;
