import YouTubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Link,
  List,
  ListItem,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import { useGetLeaderboards, useGetLeaderboardScores } from "../api/requests";
import { Leaderboard } from "../models/leaderboards";

function LeaderboardCard({
  level,
  coop,
}: {
  level: Leaderboard;
  coop: boolean;
}) {
  const { data: scores, isLoading } = useGetLeaderboardScores(
    level.id,
    1,
    10,
    coop
  );

  return (
    <Box sx={{ minWidth: 300 }}>
      <Card>
        <CardHeader
          title={
            <Link noWrap href={`/leaderboard/${level.id}`} color={"inherit"}>
              {level.name}
            </Link>
          }
          sx={{ textAlign: "center" }}
        />
        <Divider />
        {isLoading || scores === undefined ? (
          <CardContent sx={{ justifyContent: "center", display: "flex" }}>
            <CircularProgress />
          </CardContent>
        ) : (
          <CardContent>
            <List>
              {scores.data.map((run, index) => (
                <ListItem key={run.id} sx={{ margin: 0, padding: 0 }}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ textAlign: "right", width: "22px" }}
                  >
                    {`${index + 1})`}
                  </Typography>
                  <Link
                    key={run.id}
                    variant="body2"
                    color="inherit"
                    href={`/run/${run.id}`}
                    sx={{ left: "30px", position: "absolute" }}
                  >
                    {coop
                      ? `${run.primary_runner.name} and ${run.secondary_runner?.name} - ${run.score}`
                      : `${run.primary_runner.name} - ${run.score}`}
                  </Link>
                  {run.primary_video && (
                    <IconButton
                      href={run.primary_video}
                      target={"_blank"}
                      rel="noreferrer"
                      sx={{
                        margin: 0,
                        padding: "2px",
                        right: 0,
                        position: "absolute",
                      }}
                    >
                      <YouTubeIcon color={"error"} />
                    </IconButton>
                  )}
                </ListItem>
              ))}
            </List>
          </CardContent>
        )}
      </Card>
    </Box>
  );
}

export default function Home() {
  const [solo, setSolo] = React.useState(
    (localStorage.getItem("solo") || "true") === "true"
  );

  const toggleSolo = () => {
    setSolo(!solo);
    localStorage.setItem("solo", (!solo).toString());
  };

  const { data: leaderboards, isLoading } = useGetLeaderboards();

  if (isLoading || leaderboards === undefined) {
    return <CircularProgress />;
  }

  const levels = leaderboards
    .map((leaderboard) => leaderboard.levels)
    .flat()
    .filter((leaderboard) => (solo && leaderboard.can_solo) || !solo);

  return (
    <>
      <FormControlLabel
        control={<Switch checked={!solo} onChange={() => toggleSolo()} />}
        label="coop"
        sx={{ marginTop: 15, justifyContent: "center", display: "flex" }}
      />
      <Grid
        container
        spacing={5}
        marginLeft={10}
        marginRight={10}
        marginBottom={10}
        justifyContent={"space-between"}
      >
        {levels.map((level) => (
          <LeaderboardCard key={level.id} level={level} coop={!solo} />
        ))}
      </Grid>
    </>
  );
}
