import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import React from "react";
import { request } from "../../api/base";
import { useGetRun } from "../../api/requests";
import { Run } from "../../models/leaderboards";
import { formatTime, toSeconds } from "../../utils/formatTime";

export default function EditDialog({
  editOpen,
  setEditOpen,
  run: runId,
}: {
  editOpen: boolean;
  setEditOpen: React.Dispatch<React.SetStateAction<boolean>>;
  run: string | undefined;
}) {
  const { data: run, isLoading } = useGetRun(runId, { enabled: !!runId });

  const timeErrorMessage = "Please enter a time in the format MM:SS.S";
  const scoreErrorMessage = "Please enter a score in the format #####";
  const videoErrorMessage = "Please enter a valid video link";
  const timeMatch =
    /^(?<minutes>[0-5]?\d):(?<seconds>[0-5]\d)(\.(?<milliseconds>\d{1}))?$/;
  const scoreMatch = /^\d{4,5}$/;
  const linkMatch = /^https?:\/\/.*/;

  const [error, setError] = React.useState<string | null>(null);
  const [timeError, setTimeError] = React.useState("");
  const [scoreError, setScoreError] = React.useState("");
  const [primaryVideoError, setPrimaryVideoError] = React.useState("");
  const [secondaryVideoError, setSecondaryVideoError] = React.useState("");

  const client = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: request<Run>,
    onSuccess: () => {
      client.invalidateQueries({
        queryKey: ["leaderboard", run?.leaderboard.id],
      });
      setEditOpen(false);
      window.location.reload();
    },
    onError: (error) => {
      console.log(error);

      if (axios.isAxiosError(error))
        setError(
          error.response?.data?.detail || "An unexpected error occurred"
        );
      else setError("An unexpected error occurred");
    },
  });

  const verifyTime = (e: any) => {
    const time = e.target.value;
    const match = time.match(timeMatch);

    if (match) {
      e.target.setCustomValidity("");
      setTimeError("");
    } else {
      setTimeError(timeErrorMessage);
    }
  };

  const verifyScore = (e: any) => {
    const score = e.target.value;
    const match = score.match(scoreMatch);

    if (match) {
      e.target.setCustomValidity("");
      setScoreError("");
    } else {
      setScoreError(scoreErrorMessage);
    }
  };

  const verifyPrimaryVideo = (e: any) => {
    const video = e.target.value;
    const match = video.match(linkMatch);

    if (match) {
      setPrimaryVideoError("");
    } else {
      setPrimaryVideoError(videoErrorMessage);
    }
  };
  const verifySecondaryVideo = (e: any) => {
    const video = e.target.value;
    const match = video.match(linkMatch);

    if (match) {
      setSecondaryVideoError("");
    } else {
      setSecondaryVideoError(videoErrorMessage);
    }
  };

  return (
    <React.Fragment>
      {isLoading || run === undefined ? (
        <CircularProgress />
      ) : (
        <Dialog
          open={editOpen}
          onClose={() => setEditOpen(false)}
          PaperProps={{
            component: "form",
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const form = event.currentTarget as HTMLFormElement;
              const formData = new FormData(form);
              const formJson = Object.fromEntries((formData as any).entries());
              const match = formJson.time.match(timeMatch);
              const totalSeconds = toSeconds(
                parseInt(match.groups.minutes),
                parseInt(match.groups.seconds),
                (parseInt(match.groups.milliseconds) || 0) * 100
              );
              mutate({
                url: `/runs/${run.id}`,
                method: "put",
                body: {
                  score: parseInt(formJson.score),
                  time: totalSeconds,
                  primary_video: formJson.video,
                  secondary_video: formJson.partner_video,
                },
              });
            },
          }}
        >
          <DialogTitle>Edit Run</DialogTitle>
          <DialogContent>
            <DialogContentText>Edit the run here</DialogContentText>
            <TextField
              margin="dense"
              id="runner"
              name="runner"
              label="Runner"
              defaultValue={run.primary_runner.name}
              type="text"
              variant="standard"
              disabled
              fullWidth
            />
            {run.coop && (
              <TextField
                margin="dense"
                id="partner"
                name="partner"
                label="Partner"
                defaultValue={run.secondary_runner?.name}
                type="text"
                variant="standard"
                disabled
                fullWidth
              />
            )}
            <TextField
              autoFocus
              required
              error={scoreError !== ""}
              helperText={scoreError}
              margin="dense"
              id="score"
              name="score"
              label="Score"
              type="text"
              variant="standard"
              defaultValue={run.score}
              onFocus={(event) => event.target.select()}
              onChange={verifyScore}
              fullWidth
            />
            <TextField
              required
              error={timeError !== ""}
              helperText={timeError}
              margin="dense"
              id="time"
              name="time"
              label="Time"
              type="text"
              variant="standard"
              defaultValue={formatTime(run.time)}
              onFocus={(event) => event.target.select()}
              onChange={verifyTime}
              fullWidth
            />
            <TextField
              required
              error={primaryVideoError !== ""}
              helperText={primaryVideoError}
              margin="dense"
              id="video"
              name="video"
              label="Video"
              type="text"
              variant="standard"
              defaultValue={run.primary_video}
              onFocus={(event) => event.target.select()}
              onChange={verifyPrimaryVideo}
              fullWidth
            />
            {run.coop && (
              <TextField
                required
                error={secondaryVideoError !== ""}
                helperText={secondaryVideoError}
                margin="dense"
                id="partnerVideo"
                name="partnerVideo"
                label="Partner Video"
                type="text"
                variant="standard"
                defaultValue={run.secondary_video}
                onFocus={(event) => event.target.select()}
                onChange={verifySecondaryVideo}
                fullWidth
              />
            )}
          </DialogContent>
          {error && (
            <DialogContentText
              color="error"
              align="center"
              sx={{ whiteSpace: "pre-line" }}
            >
              {error}
            </DialogContentText>
          )}
          <DialogActions>
            <ButtonGroup variant="contained" color="primary">
              <Button variant="contained" onClick={() => setEditOpen(false)}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Edit
              </Button>
            </ButtonGroup>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}
