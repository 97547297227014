import { Delete, Edit } from "@mui/icons-material";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
} from "@mui/x-data-grid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { useParams } from "react-router-dom";
import { request } from "../api/base";
import { useGetLeaderboardScores } from "../api/requests";
import { drawerWidth } from "../constants";
import { DrawerContext } from "../contexts/DrawerContext";
import { UserContext } from "../contexts/UserContext";
import { Leaderboard, SoloRunRow } from "../models/leaderboards";
import { DrawerHeader } from "../utils/DrawerHeader";
import { formatTime } from "../utils/formatTime";
import isAdmin from "../utils/isAdmin";
import EditDialog from "./dialogs/EditRun";
import RunSubmission from "./dialogs/RunSubmission";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      },
    },
  ],
}));

export default function SoloLeaderboard({
  toCoop,
  level,
}: {
  toCoop: () => void;
  level: Leaderboard;
}) {
  const drawerContext = React.useContext(DrawerContext);
  const userContext = React.useContext(UserContext);
  const { leaderboardId } = useParams<{ leaderboardId: string }>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [submissionOpen, setSubmissionOpen] = React.useState(false);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [rowToDelete, setRowToDelete] = React.useState<GridRowId | null>(null);
  const [editOpen, setEditOpen] = React.useState<boolean>(false);
  const [runToEdit, setRunToEdit] = React.useState<number | string | null>(
    null
  );
  const client = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: request<Leaderboard>,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["leaderboard", leaderboardId] }),
        window.location.reload();
    },
    onError: (error) => console.error(error),
  });
  const userIsAdmin = isAdmin();

  const columns: GridColDef<SoloRunRow>[] = [
    {
      field: "place",
      headerName: "Place",
      width: 90,
    },
    {
      field: "player",
      headerName: "Player",
      width: 150,
    },
    {
      field: "score",
      headerName: "Score",
      width: 150,
    },
    {
      field: "time",
      headerName: "Time",
      width: 150,
    },
    {
      field: "link",
      headerName: "Run Link",
      width: 160,
      renderCell: (params) => (
        <Button variant="contained" href={`/run/${params.row.id}`}>
          Run Link
        </Button>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      hideable: false,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => {
              setConfirmDelete(true);
              setRowToDelete(id);
            }}
            color="error"
          />,
          <GridActionsCellItem
            icon={<Edit />}
            label="edit"
            onClick={() => {
              setRunToEdit(id);
              setEditOpen(true);
            }}
            color="primary"
          />,
        ];
      },
    },
  ];

  const { data: scores, isLoading } = useGetLeaderboardScores(
    leaderboardId!,
    paginationModel.page + 1,
    paginationModel.pageSize,
    false
  );

  if (isLoading || !scores) {
    return <CircularProgress />;
  }

  const runs = scores.data.map((run) => ({
    id: run.id,
    place: run.place,
    player: run.primary_runner.name,
    time: formatTime(run.time),
    score: run.score,
    link: run.primary_video,
  }));

  return (
    <Main open={drawerContext.open}>
      <EditDialog
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        run={runToEdit?.toString()}
      />
      <Dialog open={confirmDelete}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" sx={{ marginBottom: 10 }}>
            Are you sure you want to delete this run?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <ButtonGroup variant="contained">
              <Button onClick={() => setConfirmDelete(false)}>Cancel</Button>
              <Button
                onClick={() =>
                  mutate({
                    url: `/runs/${rowToDelete}`,
                    method: "delete",
                  })
                }
                color={"error"}
              >
                Delete
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Dialog>
      <RunSubmission
        open={submissionOpen}
        setOpen={setSubmissionOpen}
        leaderboardId={leaderboardId!}
      />
      <DrawerHeader />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <h1>{level.name} - Solo</h1>
        {userContext.user !== null ? (
          <ButtonGroup variant="contained">
            <Button onClick={toCoop}>View Coop</Button>
            <Button onClick={() => setSubmissionOpen(true)}>Submit Run</Button>
          </ButtonGroup>
        ) : (
          <Button variant="contained" onClick={toCoop}>
            View Coop
          </Button>
        )}
      </Box>
      <Box sx={{ height: 800, width: "100%" }}>
        <DataGrid
          rows={runs}
          columns={columns}
          rowCount={scores.count}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 25]}
          disableRowSelectionOnClick
          columnVisibilityModel={{ actions: userIsAdmin }}
        />
      </Box>
    </Main>
  );
}
