import { useQuery } from "@tanstack/react-query";
import { Category, Leaderboard, Run, Runner } from "../models/leaderboards";
import { PaginatedResponse } from "../models/pagination";
import { request } from "./base";

// Leaderboard API requests
export const useGetLeaderboards = () => {
  return useQuery({
    queryKey: ["leaderboards"],
    queryFn: () => request<Leaderboard[]>({ url: "/leaderboards/" }),
    select: (data) => {
      return [
        new Category("tier 1", data.slice(0, 4)),
        new Category("tier 2", data.slice(4, 8)),
        new Category("tier 3", data.slice(8, 12)),
        new Category("tier 4", data.slice(12, 16)),
        new Category("dlc", data.slice(16)),
      ];
    },
  });
};

export const useGetLeaderboard = (leaderboardId: string | number) => {
  return useQuery<Leaderboard>({
    queryKey: ["leaderboard", leaderboardId.toString()],
    queryFn: () => request({ url: `/leaderboards/${leaderboardId}` }),
  });
};

export const useGetLeaderboardScores = (
  leaderboardId: string | number,
  page: number,
  limit: number,
  coop: boolean
) => {
  console.log(["leaderboard", leaderboardId.toString(), page, limit, coop]);
  return useQuery<PaginatedResponse<Run>>({
    queryKey: ["leaderboard", leaderboardId.toString(), page, limit, coop],
    queryFn: () =>
      request({
        url: `/leaderboards/${leaderboardId}/runs`,
        params: { page, limit, coop },
      }),
  });
};

// Run API requests
export const useGetRun = (
  runId: string | number | undefined,
  options?: object
) => {
  return useQuery<Run>({
    queryKey: ["run", runId?.toString()],
    queryFn: () => request({ url: `/runs/${runId}` }),
    ...options,
  });
};

// User API requests
export const useGetUser = (options?: object) => {
  const token = localStorage.getItem("token");

  return useQuery<Runner>({
    enabled: !!token,
    queryKey: ["user"],
    queryFn: () => request({ url: "/users/me" }),
    ...options,
  });
};
